<script setup>
import { ref } from 'vue';
import OCardBodyCell from 'o365.vue.component.Card.BodyCell.vue';

const props = defineProps({
    dataColumns: Object,
    item: Object,
    showImages: Boolean,
    imageUrl: String
    });

const failedToLoad = ref(false);

</script>

<template>
    <div class="d-flex flex-grow-1">
        <div v-if="showImages">
            <img class="card-image" :src="imageUrl" @error="failedToLoad = true" v-if="imageUrl && !failedToLoad"/>
            <slot v-else name="fallbackImage">
            </slot>
        </div>

        <div class="d-flex flex-wrap flex-grow-1">
            <div 
                v-for="(column, colIndex) in dataColumns.columns" 
                class="card-text d-block align-text-top"
                :class="'colspan-' + column.colspan">
                <OCardBodyCell :col="column" :row="item" 
                    :colIndex="colIndex"
                    :is-active-edit-cell="false" />
                    
            </div>
        </div>
    </div>
    
</template>

<style scoped>
    .card-text {
        padding: 3px;
    }
</style>

<style lang="scss">
@for $i from 1 through 12 {
  .colspan-#{$i} {
    flex: 0 0 #{100% * ($i / 12)};
  }
}
</style>